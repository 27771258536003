document.addEventListener('DOMContentLoaded', function() {
    // init
    console.log('hi dom 👋');

    const header = document.querySelector('.site-header');
    window.addEventListener('scroll', () => {
        if (window.scrollY > 100) {
            header.classList.add('scrolled');
        }
        else{
            if (header.classList.contains('scrolled')){
                header.classList.remove('scrolled');
            }

        }
    });
});